import React, { useEffect, useState } from "react";
import Datetime from "react-datetime";
import "react-datetime/css/react-datetime.css";
import back_white from "../assets/images/back_white.svg";
import birthday_icon from "../assets/images/birthday.svg";
import birthdayCake from "../assets/images/birthdayCake.svg";
import noContactImage from "../assets/images/no-contact1.svg";

import LovedOnePhoneName from "../components/LovedPhoneName";

import useSelectedLovedOne from "../hooks/useSelectedLovedOne";
import { useNavigate } from "react-router-dom";
import { NavLink } from "react-router-dom";
import { getAnnualReminder, changeAnnualReminderStatus, deleteAnnualReminder } from "../api/api";
import { Spinner, Modal } from "react-bootstrap";
import dayjs from "dayjs";
import { useErrorHandler } from "react-error-boundary";
import deleteConfirmIcon from "assets/images/delete_confrm.svg";
import deleteIcon from "assets/images/delete.svg";
import { speakText } from "utils/utils";
import loader from "assets/images/loader.gif"

export default function PFAManageBirthday() {
  const loved_one = useSelectedLovedOne();
  const navigate = useNavigate();
  const [annual_reminders, setReminders] = useState([]);
  const [isLoading, setLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);

  const handleError = useErrorHandler();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  useEffect(() => {
    setLoading(true);
    if (loved_one && loved_one.id) {
      getAnnualReminder({ lovedOneId: loved_one ? loved_one.id : "" })
        .then((res) => {
          const { data, status } = res;
          setReminders(data.data.data);
          setLoading(false);
        })
        .catch((error) => {
          setReminders([]);
          setLoading(false);
          return error.response.data.statusCode != 400
            ? handleError(error)
            : "";
        });
    }
  }, [loved_one && loved_one.id]);

  const handleChange = async (event) => {
    const { id, checked } = event.target;
    let data = {
      lovedOneId: loved_one.id,
      is_active: checked ? 1 : 0,
      id,
    };
    changeAnnualReminderStatus(data)
      .then((res) => console.log("res :>> ", res))
      .catch((error) => {
        return error.response.data.statusCode != 400 ? handleError(error) : "";
      });
  };
  const handleModal =
    (status = false, item = false) =>
      () => {
        if (item.primary) {
          return false;
        }
        setShowModal(status ? item : false);
      };

  const handelDelete = async () => {
    let data = {
      lovedOneId: loved_one.id,
      id: showModal,
    };
    deleteAnnualReminder(data)
      .then((res) => {
        setShowModal(0);
        let newList = annual_reminders.filter((reminder) => {
          if (reminder.id != data.id) {
            return reminder;
          }
        });
        setReminders(newList)
      })
      .catch((error) => {
        return error.response.data.statusCode != 400 ? handleError(error) : "";
      })
  }

  let list = annual_reminders.length ? (
    annual_reminders.map((reminder, i) => (
      <div className="boxwhite newUIReminer annualReminder">
        <div
          className="bdayTitle"
          onClick={() => navigate("/Birthday", { state: reminder })}
        >
          <h5 className="fsmed">{reminder.message}</h5>
          <div className="">
            <h5 className="mb-0 fsreg">
              <img src={birthdayCake} className="me-2" />
              {dayjs(reminder.event_date).format("DD MMM")}
            </h5>
          </div>
        </div>

        <div className="form-check form-switch">
          <button
            style={{ border: 0, backgroundColor: "#fff" }}
            onClick={() => setShowModal(reminder.id)}>
            <img src={deleteIcon} height="20" width="20" style={{ marginBottom: "10px" }} />
          </button>
          <input
            className="form-check-input"
            type="checkbox"
            id={reminder.id}
            defaultChecked={reminder.is_active}
            onChange={handleChange}
          />
          <label className="form-check-label" htmlFor={reminder.id}></label>
        </div>
      </div>
    ))
  ) : (
    <div className=" text-center nocontact">
      <img src={noContactImage} alt="no contact" />
      <h3 className="pt-4">No reminder found</h3>
    </div>
  );

  if (isLoading)
    return (
      <div
        style={{
          minHeight: "100vh",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        {/* <Spinner animation="border" variant="primary" size="100px" /> */}
        <img src={loader} alt="Loading..." className="custom-loader" />

      </div>
    );
  return (
    <>
      <div className="mainPage">
        <div className="Homeheaderbg ">
          <div className="">
            <NavLink to="/MenuScreen">
              <img src={back_white} alt="" title="" onClick={() => speakText('Back', true)} />
            </NavLink>
          </div>
          <div className="customer-info">
            <div className="col-md-12">
              <h3 className="fs32">Add</h3>
              <h1 style={{ fontSize: "29px" }}>Birthday/Anniversary</h1>
            </div>
          </div>
        </div>
        <LovedOnePhoneName loved_one={loved_one} className="mtminus" />

        <div className="contentBody contentboxgray  contentbodymedia">
          <div className="row justify-content-center">
            <div className="col-md-12">
              <div className="textstyle">
                <p className="pt-4">
                  {loved_one?.first_name} never needs to worry they will miss an
                  important date. You can remind {loved_one?.first_name} about
                  anniversaries and birthdays by entering them here. A reminder
                  will pop up on their Big<b>Purple</b>Phone on the day you enter.
                </p>
              </div>
              <div className="media boxmediablue text-white mt-3">
                <NavLink to="/Birthday" className="anchoroveray"></NavLink>

                <img
                  className="align-self-center me-3"
                  src={birthday_icon}
                  alt=""
                  title=""
                />
                <div className="media-body">
                  <h5 className="mt-0">
                    <b>Add Birthday/Anniversary</b>
                  </h5>
                </div>
              </div>
            </div>
          </div>
          <div className="mt-3">{list}</div>
        </div>
      </div>
      <Modal
        centered
        show={showModal}
        onHide={handleModal(false)}
      >
        <Modal.Header closeButton>
          <Modal.Title style={{ width: "100%" }}>
            <h3 className="text-center">Confirmation</h3>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p className="mb-3 text-center">
            <img
              src={deleteConfirmIcon}
              alt="delete confirmation"
            />
          </p>

          <p className="text-center">
            Are you sure you want to delete this reminder?
          </p>
        </Modal.Body>
        <Modal.Footer>
          <button
            type="button"
            className="btn btn-primary"
            onClick={handleModal(false)}
          >
            Cancel
          </button>
          <button
            type="button"
            className="btn btn-danger"
            onClick={handelDelete}
          >
            Ok
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
