import React, { useEffect, useState } from "react";
import back_white from "assets/images/back_white.svg";
import menu_sos from "assets/images/menu_sos.svg";
import menu_accessibility from "assets/images/menu_accessibility.svg";
import manage_media_new from "assets/images/manage_media_new.png";
import menu_games from "assets/images/gameApp.svg";
import menu_news from "assets/images/newsApp.svg";
import menu_whatsup from "assets/images/WhatsApp.png";
import web_apps from "assets/images/web_apps.png";
import custom_browser from "assets/images/custom_browser.svg";
import menu_covid_check_in from "assets/images/covid-checkin.svg";
import menu_help from "assets/images/menu_help.png";
import manage_media from "assets/images/manage_media.png";
import internet from "assets/images/internet.png";
import Billing from "assets/images/billing.svg";
import Support from "assets/images/supportVideo.svg";
import Contacts from "assets/images/contact.svg";
import Birthday from "assets/images/birthdaysAnniversaries.svg";
import phone_media from "assets/images/phone-media.png";
import videocall from "assets/images/videocall.png";
import message_media from "assets/images/message_media.png";
import photos from "assets/images/photos.png";
import Reminders from "assets/images/reminders.svg";
import wifi from "assets/images/Wifi.png";
import bluetooth from "assets/images/bluetooth.png";
import camera_media from "assets/images/camera_media.png";
import weather_media from "assets/images/weather_media.png";
import radio_media from "assets/images/radio_media.png";
import music_media from "assets/images/music_media.png";
import sos_media from "assets/images/sos_media.png";
import vaccination_media from "assets/images/vaccination_media.png";
import useSelectedLovedOne from "hooks/useSelectedLovedOne";
import {
  getWebUrlDetails,
  setAppProtected,
  updateWebUrlDetails,
} from "api/api";
import Facebook from "assets/images/Facebook.png";
import Messenger from "assets/images/Messenger.png";
import Instagram from "assets/images/Instagram.png";
import Whatsapp from "assets/images/WhatsApp.png";
import WeChat from "assets/images/WeChat.png";
import Zoom from "assets/images/Zoom.png";
import Gmail from "assets/images/Gmail.png";
import ICloudMail from "assets/images/ICloudMail.png";
import Yahoo from "assets/images/Yahoo.png";
import Outlook from "assets/images/Outlook.png";
import Maps from "assets/images/Maps.png";
import Google from "assets/images/Google.png";
import PinChangeIcon from "../assets/images/birthdayCake.svg";
import Uber from "assets/images/Uber.png";
import UberEats from "assets/images/UberEats.png";
import Menulog from "assets/images/Menulog.png";
import MyGov from "assets/images/MyGov.png";
import ServiceSa from "assets/images/ServiceSA.png";
import AccessCanberra from "assets/images/AccessCanberra.png";
import ServiceNSW from "assets/images/ServiceNSW.png";
import ServiceTAS from "assets/images/ServiceTAS.png";
import ServiceVIC from "assets/images/ServiceVIC.png";
import ServiceNT from "assets/images/ServiceNT.png";
import ServiceWa from "assets/images/ServiceWA.png";
import ServiceQLD from "assets/images/ServiceQLD.png";
import ABCNews from "assets/images/ABCNews.png";
import Spotify from "assets/images/Spotify.png";
import YouTube from "assets/images/YouTube.png";
import YouTubeMusic from "assets/images/YouTube Music.png";
import { toast } from "react-toastify";
import audible from "assets/images/audible.png";
import pinlocked from "assets/images/pinlocked.png";
import pinunlocked from "assets/images/pinunlocked.png";
import CustomModel from "components/CustomModel";
import { NavLink } from "react-router-dom";
import LovedOnePhoneName from "components/LovedPhoneName";
import { speakText } from "utils/utils";

const ImageComponent = {
  menu_phone: phone_media,
  menu_video_call: videocall,
  menu_messages: message_media,
  menu_camera: camera_media,
  menu_photos: photos,
  menu_weather: weather_media,
  manage: manage_media_new,
  menu_radio: radio_media,
  menu_games: menu_games,
  menu_news: menu_news,
  menu_sos: menu_sos,
  menu_accessibility: menu_accessibility,
  menu_whatsup: menu_whatsup,
  custom_browser: custom_browser,
  menu_music: music_media,
  menu_vaccination: vaccination_media,
  menu_covid_check_in: menu_covid_check_in,
  menu_help: menu_help,
  menu_sos: sos_media,
  Icon_Internet: internet,
  web_apps: web_apps,
  manage_media: manage_media,
  Billing,
  Support,
  Contacts,
  Birthday,
  Reminders,
  wifi: wifi,
  bluetooth: bluetooth,
  Icon_Facebook: Facebook,
  Icon_Messenger: Messenger,
  Icon_Instagram: Instagram,
  Icon_WhatsApp: Whatsapp,
  Icon_WeChat: WeChat,
  Icon_Zoom: Zoom,
  Icon_Gmail: Gmail,
  Icon_Outlook: Outlook,
  Icon_Yahoo: Yahoo,
  Icon_ICloud_Mail: ICloudMail,
  Icon_Maps: Maps,
  Icon_Google: Google,
  Icon_Uber: Uber,
  Icon_Menulog: Menulog,
  Icon_UberEats: UberEats,
  Icon_MyGov: MyGov,
  Icon_AccessCanberra: AccessCanberra,
  Icon_ServiceNSW: ServiceNSW,
  Icon_ServiceNT: ServiceNT,
  Icon_ServiceQLD: ServiceQLD,
  Icon_ServiceTAS: ServiceTAS,
  Icon_ServiceVIC: ServiceVIC,
  Icon_ServiceWA: ServiceWa,
  Icon_ServiceSA: ServiceSa,
  Icon_YouTube: YouTube,
  icon_ABCNews: ABCNews,
  Icon_YouTube_Music: YouTubeMusic,
  Icon_Spotify: Spotify,
  Icon_Audible: audible,
  pinlocked,
  pinunlocked,
};

export default function WebApps() {
  const lovedone = useSelectedLovedOne();
  console.log("loved one in web apps", lovedone);
  const [data, setData] = useState([]);
  const [updateTrigger, setUpdateTrigger] = useState(0);
  const [optionsData, setOptions] = useState([]);
  const [showPinModal, setShowPinModal] = useState(false);
  const [pin, setPin] = useState("");
  const [lockedApps, setLockedApps] = useState([]);
  const [formSubmitted, setIsformSubmitted] = useState(false);
  const [SelecteOption, setSelectedOption] = useState([]);

  useEffect(() => {
    getWebData();
  }, [lovedone && lovedone.id && updateTrigger, setLockedApps]);

  const getWebData = () => {
    if (lovedone && lovedone.id) {
      getWebUrlDetails({ id: lovedone.id, country: lovedone.country }).then(
        (res) => {
          setData(res.data.data.external_menu);
          setOptions(res.data.data?.lo_external_menu);
          setPin(res.data.data.lovedOnePin[0]?.pin);

          // Determine which menu items are locked and update the lockedApps state accordingly
          const lockedMenuIds = res.data.data?.lo_external_menu
            .filter((item) => item.pin_id !== null) // Filter out items with pin_id not null
            .map((item) => item.menu_id); // Get the menu_id of locked items

          setLockedApps(lockedMenuIds);
        }
      );
    }
  };
  const categoryOptions = {};

  data.forEach((item) => {
    if (!categoryOptions[item.category_title]) {
      categoryOptions[item.category_title] = [];
    }
    const loExternalMenu = optionsData.map((opt) => opt.menu_id);
    const pinData = optionsData.reduce((acc, opt) => {
      if (opt.menu_id === item.menu_id) {
        return opt.is_locked;
      }
      return acc;
    }, null);
    if (
      !categoryOptions[item.category_title].some(
        (opt) => opt.value === item.menu_title
      )
    ) {
      categoryOptions[item.category_title].push({
        label: item.menu_title,
        value: item.menu_title,
        menuId: item.menu_id,
        icon_name: item.icon_name,
        isChecked: loExternalMenu.includes(item.menu_id),
        is_locked: pinData || 0, // Default to 0 if pinData is null
      });
    }
  });
  const options = Object.keys(categoryOptions).map((category) => ({
    label: category,
    options: categoryOptions[category],
  }));

  const handleUpdate = (menuId) => async (event) => {
    if (optionsData.length >= 5 && event.target.checked) {
      toast("Oops! You've reached the maximum limit of 5 WebApps", {
        type: "error",
      });
      return;
    }
    const requestBody = {
      external_menu_id: menuId,
      loved_one_id: lovedone.id,
      isChecked: event.target.checked,
    };
    updateWebUrlDetails(requestBody).then((res) => {
      // Handle response if needed
      toast(`${res?.data?.message}`, { type: "success" });
      setUpdateTrigger((prevTrigger) => prevTrigger + 1);
    });
  };

  const handleSetPinClick = () => {
    setShowPinModal(true); // Show the modal when button is clicked
    console.log("checking pin", showPinModal);
  };

  // Function to handle modal close
  const handleCloseModal = () => {
    setShowPinModal(false); // Hide the modal
  };

  const handleAppLock = (option) => async () => {
    setSelectedOption(option);
    const requestBody = {
      loved_one_id: lovedone.id,
      external_menu_id: option.menuId,
      lock_status: option.is_locked === 1 ? 0 : 1,
    };

    if (!pin) {
      // If no PIN is set, open the set PIN modal
      handleSetPinClick();
      return; // Return early to prevent further execution
    }
    setAppProtected(requestBody).then((res) => {
      toast(`${res?.data?.message}`, { type: "success" });
      getWebData();
    });
  };
  return (
    <>
      <div class="mainPage">
        <div class="Homeheaderbg  text-white">
          <div class="d-flex align-self-center">
            <NavLink to="/MenuScreen">
              <img src={back_white} onClick={() => speakText("Back", true)} />
            </NavLink>
          </div>
          <div className="d-flex header-info mt-4">
            <h4>
              Select Additional
              <br /> <span>Apps</span>{" "}
            </h4>
            <img src={web_apps} className="header-webapp-img ms-auto" />
          </div>
        </div>
        <div class="parent parentheight">
          <LovedOnePhoneName loved_one={lovedone} />
        </div>
        <div class="contentBody contentboxgray contentbodymedia ">
          <div class="contentbodypadd pt-4 pb-4">
            <div className="d-flex align-items-start">
              <div className="col-8">
                You can add up to 5 Apps on {lovedone?.first_name}'s Big
                <strong>Purple</strong>Phone. You can also set an optional PIN
                number for access to specific Apps.
              </div>
              {pin ? (
                <button
                  onClick={handleSetPinClick}
                  className="setpinbtn ms-auto"
                >
                  Update PIN Number
                </button>
              ) : (
                <button
                  onClick={handleSetPinClick}
                  className="setpinbtn ms-auto"
                >
                  Set Pin Number
                </button>
              )}
            </div>
            <h5
              className="mt-3 mb-4"
              style={{ fontFamily: "helveticaneuemedium" }}
            >
              Select up to 5 additional Apps
            </h5>

            {options?.map((item, index) => (
              <div className="media-box-white text-grey1">
                <h5 className="fw_medium fs-20 mt-3">{item.label}</h5>
                <div className="row">
                  {item.options.map(
                    (option, idx) => (
                      console.log(),
                      (
                        <div className="col-sm-6 my-2">
                          <div className="d-flex align-items-center">
                            <img
                              className="align-self-center media-list-img"
                              src={ImageComponent[option.icon_name]}
                              alt=""
                              title=""
                            />
                            <div className="ms-3">
                              <div className="form-check form-switch ms-3">
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  id={`option_${index}_${idx}`}
                                  checked={option.isChecked}
                                  onChange={handleUpdate(option.menuId)}
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor={`option_${index}_${idx}`}
                                ></label>
                              </div>
                              {option.isChecked && (
                                <>
                                  <p
                                    className="text-center mt-3"
                                    onClick={handleAppLock(option)}
                                  >
                                    <img
                                      className="icon-img"
                                      src={
                                        option.is_locked === 1
                                          ? pinlocked
                                          : pinunlocked
                                      }
                                      alt={option}
                                      title=""
                                    />
                                  </p>
                                  {/* <CustomModel
                      title={pin ? 'Change Pin' : 'Enter Pin'}
                      onHide={handleCloseModal}
                      show={showPinModal}
                      pinData={pin}
                      setPin={setPin}
                      getWebData={getWebData}
                      setIsformSubmitted={setIsformSubmitted}
                      formSubmitted={formSubmitted}
                      option={SelecteOption}
                    /> */}
                                </>
                              )}
                            </div>
                          </div>
                        </div>
                      )
                    )
                  )}
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
      <CustomModel
        title={pin ? "Change Pin" : "Enter Pin"}
        onHide={handleCloseModal}
        show={showPinModal}
        pinData={pin}
        setPin={setPin}
        getWebData={getWebData}
        setIsformSubmitted={setIsformSubmitted}
        formSubmitted={formSubmitted}
        option={SelecteOption}
      />
    </>
  );
}
