import { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import useSelectedLovedOne from "hooks/useSelectedLovedOne";
import {
  getCarrierList,
  getSettings,
  lovedOneSelectedCarrier,
  setCarrier,
  updateSelectToSpeak,
  updateSettings,
  updateSpeakerSetting,
  updateTalkBackSetting,
} from "../api/api";
import back_white from "assets/images/back_white.svg";
import { useErrorHandler } from "react-error-boundary";
import CustomModal from "components/Modal/modal";
import deleteConfirmIcon from "assets/images/delete_confrm.svg";
import loPhoneDemo from "assets/images/Enlarged_icons_on_BigPurplePhone.png";
import closeIcon from "assets/images/pop_close_icon.svg";
import voicemail from "assets/images/voiceCall.png";
import recentCallIcon from "assets/images/recent-call-icon.svg";
import iconsize from "assets/images/iconsize-icon.svg";
import privatecaller from "assets/images/privatecaller-icon.svg";
import speaker from "assets/images/speaker-icon.svg";
import talkback from "assets/images/big-pupletalk.svg";
import { speakText } from "utils/utils";
import { useDispatch, useSelector } from "react-redux";
import { updateSettingsSlice } from "redux/settingsSlice";
import Select from "react-select";
import informationIcon from "assets/images/informationIcon.svg";
import { toast } from "react-toastify";

const PhoneMenuScreen = () => {
  const [isLoading, setLoading] = useState(false);
  const [isOpenModal, setOpenModal] = useState(false);
  const [isDemoOpenModal, setDemoOpenModal] = useState(false);
  const lovedOne = useSelectedLovedOne();

  console.log("lovedOne", lovedOne);
  const [Settings, setSettings] = useState({});
  const handleError = useErrorHandler();
  const dispatch = useDispatch();
  const settings = useSelector((state) => state.settings);
  const [selectedValues, setSelectedValues] = useState({
    label: "Please select (default)",
    value: 0,
  });
  const [options, setOptions] = useState([]);
  const settingData = JSON.parse(localStorage.getItem("settings"));
  const [hoveredElement, setHoveredElement] = useState(null);
  const [voicemailNumber, setVoicemailNumber] = useState("");
  const [enableToggleForOther, setEnableToggleForOther] = useState(false);
  const [isToggleCheck, setIsToggleCheck] = useState();

  useEffect(() => {
    setLoading(true);
    if (lovedOne && lovedOne.id) {
      getSettings(lovedOne?.id ?? "")
        .then((data) => {
          console.log("line no 58", data);
          setLoading(false);
          setSettings(data);
        })
        .catch((error) => {
          return error.response.data.statusCode != 400
            ? handleError(error)
            : "";
        });
    }
  }, [lovedOne && lovedOne.id, setSettings]);

  useEffect(() => {
    setLoading(true);
    getCarrierList()
      .then((data) => {
        const transformedOptions = [
          { label: "Please select (default)", value: 0 },
          ...data.data.data.map((item) => ({
            value: item.id,
            label: `${item.name} - ${item.phone_number}`,
          })),
        ];

        setLoading(false);
        setOptions(transformedOptions);
      })
      .catch((error) => {
        return error.response.data.statusCode != 400 ? handleError(error) : "";
      });
    let payload = {
      LovedOneId: settingData.loved_one_id,
    };
    lovedOneSelectedCarrier(payload)
      .then((lovedOneData) => {
        // console.log("line no 92", lovedOneData.data?.data[0].phone_number)
        if (lovedOneData.data.data.length > 0) {
          if (lovedOneData.data?.data[0]?.phone_number) {
            setVoicemailNumber(lovedOneData.data?.data[0]?.phone_number);
            setEnableToggleForOther(true);
          }
          const transformedOptions = lovedOneData.data.data.map((item) => ({
            value: item.id,
            label: `${item.name} - ${item.phone_number}`,
          }));
          setSelectedValues(transformedOptions);
        }
      })
      .catch((error) => {
        console.log("Error", error);
        if (error.response.data.statusCode !== 400) {
          handleError(error);
        }
      })
      .finally(() => {
        // Set loading to false when both requests are done
        setLoading(false);
      });

    // }
  }, []);

  const handelRecentCall = (value) => {
    setLoading(true);
    setSettings({ ...Settings, show_recent_call: parseInt(value) });
    updateSettings({
      ...Settings,
      lovedOneId: lovedOne?.id,
      show_recent_call: parseInt(value),
    })
      .then((data) => {
        setLoading(false);
      })
      .catch((error) => {
        return error.response.data.statusCode != 400 ? handleError(error) : "";
      });
  };
  const handelSettings = (value) => {
    value = Settings.voice_mail ? 0 : 1;
    setSettings({ ...Settings, voice_mail: value });
    !(
      lovedOne?.country == "United Kingdom" ||
      lovedOne?.country == "New Zealand"
    ) && setOpenModal(false);
    updateSettings({ ...Settings, lovedOneId: lovedOne?.id, voice_mail: value })
      .then((data) => {
        const statusText = value ? "enabled" : "disabled";
        speakText(`Voicemail is now ${statusText} on the BigPurplePhone`, true);
      })
      .catch((error) => {
        return error.response.data.statusCode != 400 ? handleError(error) : "";
      })
      .finally(() => {
        !(
          lovedOne?.country == "United Kingdom" ||
          lovedOne?.country == "New Zealand"
        ) && setOpenModal(false);
      });
  };
  const handleNoSpam = () => {
    let value = Settings.no_spam ? 0 : 1;

    setSettings({ ...Settings, no_spam: value });
    updateSettings({ ...Settings, lovedOneId: lovedOne?.id, no_spam: value })
      .then((data) => {
        const statusText = value ? "enabled" : "disabled";
        speakText(
          `Private Caller is now ${statusText} on the BigPurplePhone`,
          true
        );
      })
      .catch((error) => {
        return error.response.data.statusCode != 400 ? handleError(error) : "";
      })
      .finally(() => {
        setOpenModal(false);
      });
  };
  const handleSpeaker = () => {
    // Toggle the value of auto_speaker
    const newValue = Settings.is_auto_speaker === "true" ? "false" : "true";

    const apiValue = Settings.is_auto_speaker === "true" ? "1" : "0";
    // Update local state immediately to reflect the change in UI
    setSettings({ ...Settings, is_auto_speaker: newValue });

    // Make API call to update the setting
    updateSpeakerSetting({ lovedOneId: lovedOne?.id, auto_speaker: apiValue })
      .then(() => {
        // Optionally, you can add any post-update logic here
        const statusText = newValue === "true" ? "enabled" : "disabled";
        speakText(
          `Auto Speaker is now ${statusText} on the BigPurplePhone`,
          true
        );
      })
      .catch((error) => {
        // Handle errors if needed
        console.error("Error updating auto speaker setting:", error);
        // Rollback the UI state if the API call fails
        setSettings({
          ...Settings,
          is_auto_speaker:
            Settings.is_auto_speaker === "true" ? "false" : "true",
        });
      });
  };

  const handleTalkBack = () => {
    // Toggle the value of auto_speaker
    const newValue = Settings.talk_back === "true" ? "false" : "true";

    const apiValue = Settings.talk_back === "true" ? "1" : "0";
    // Update local state immediately to reflect the change in UI
    setSettings({ ...Settings, talk_back: newValue });

    // Make API call to update the setting
    updateTalkBackSetting({ talk_back: apiValue, lovedOneId: lovedOne?.id })
      .then(() => {
        // Optionally, you can add any post-update logic here
        const statusText = newValue === "true" ? "enabled" : "disabled";
        speakText(
          `BigPurple Talk is now ${statusText} on the BigPurplePhone`,
          true
        );
      })
      .catch((error) => {
        // Handle errors if needed
        console.error("Error updating talkBackSetting setting:", error);
        // Rollback the UI state if the API call fails
        setSettings({
          ...Settings,
          talk_back: Settings.talk_back === "true" ? "false" : "true",
        });
      });
  };

  const handleSelectToSpeak = () => {
    const newValue = Settings.select_to_speak === "true" ? "false" : "true";
    const apiValue = newValue === "true" ? "0" : "1";

    setSettings({ ...Settings, select_to_speak: newValue });

    // Update Redux state
    dispatch(
      updateSettingsSlice({
        lovedOneId: lovedOne.id,
        settings: { select_to_speak: newValue, loved_one_id: lovedOne.id },
      })
    );

    // Make API call to update the setting
    updateSelectToSpeak({ select_to_speak: apiValue, lovedOneId: lovedOne.id })
      .then(() => {
        const statusText = newValue === "true" ? "enabled" : "disabled";
        speakText(`Select to speak is now ${statusText}`, newValue);
      })
      .catch((error) => {
        console.error("Error updating selectToSpeak setting:", error);
      });
  };
  const handleModal = (status) => () => {
    setOpenModal(status);
  };
  const handelDemoModel = (status) => () => {
    setDemoOpenModal(status);
  };
  const handelSize = (value) => {
    value = Settings.avatars_size ? 0 : 1;
    setSettings({ ...Settings, avatars_size: value });
    updateSettings({
      ...Settings,
      lovedOneId: lovedOne?.id,
      avatars_size: value,
    })
      .then((data) => {
        const statusText = value ? "larger" : "smaller";
        speakText(
          `Icon Size is  now ${statusText} on the BigPurplePhone`,
          true
        );
      })
      .catch((error) => {
        return error.response.data.statusCode != 400 ? handleError(error) : "";
      });
  };
  const handleChange = (values) => {
    setSelectedValues(values);
  };

  const carrierSelect = () => {
    const data = {
      country: lovedOne.country,
      carerId: selectedValues?.value,
      lovedOneId: lovedOne?.id,
    };

    setCarrier(data).then((response) => {
      toast(response.data.message, { type: "success" });
    });
  };

  const saveVoicemailNo = async () => {
    const payload = {
      lovedOneId: lovedOne.id,
      country: lovedOne.country,
      carerPhoneNumber: voicemailNumber,
    };
    console.log("voicemailNumber *****", voicemailNumber);
    setCarrier(payload).then((response) => {
      toast(response.data.message, { type: "success" });
    });

    setEnableToggleForOther(true);
  };
  const handleMouseOver = (element) => {
    setHoveredElement(element);
  };

  const handleMouseOut = () => {
    setHoveredElement(null);
  };

  const formatOptionLabel = ({ label }) => {
    if (label.includes("Purple")) {
      const [beforePurple, afterPurple] = label.split("Purple");

      return (
        <div>
          {beforePurple}
          <strong>Purple</strong>
          {afterPurple}
        </div>
      );
    }

    return <div>{label}</div>;
  };

  console.log("selectedValues", voicemailNumber);

  return (
    <div className="mainPage">
      <div className="Homeheaderbg">
        <div className="">
          <NavLink to="/MenuScreen">
            <img
              src={back_white}
              alt=""
              onClick={() => speakText("Back", true)}
            />
          </NavLink>
        </div>
        <div className="customer-info">
          <h1 className="w-100">
            <span>Phone Settings</span>
          </h1>
        </div>

        <div className="customer-info">
          <div className="col-md-6"></div>
          <div className="col-md-6"></div>
        </div>
      </div>

      <div className="parent titleHeadBox">
        <div className="contentboxgray11">
          <br />
        </div>
      </div>
      <div className="contentBody contentbodymedia manageapps">
        <div className="">
          <div className="phone-set-row">
            <div className="phone-set-img">
              <img src={recentCallIcon} />
            </div>
            <div className="phone-set-col-content">
              <h5 style={{}}>Display Recent Calls </h5>
              <div className="d-flex align-items-center">
                <p className="subTitleBlog mb-2" style={{ lineHight: "1.4" }}>
                  Set the number of recent calls that displays on{" "}
                  {lovedOne.first_name}'s Phone
                </p>
              </div>
              <div className="row" style={{ marginBottom: "0px" }}>
                <span className="text-left contact-radio">
                  <input
                    type={"radio"}
                    onChange={(event) => {
                      handelRecentCall(event.target.value);
                    }}
                    checked={Settings.show_recent_call == 0 ? true : false}
                    name="total"
                    value="0"
                  />
                  0
                </span>
                <span className="text-left contact-radio">
                  <input
                    type={"radio"}
                    onChange={(event) => {
                      handelRecentCall(event.target.value);
                    }}
                    checked={Settings.show_recent_call == 3 ? true : false}
                    name="total"
                    value="3"
                  />
                  3
                </span>
                <span className="text-left contact-radio">
                  <input
                    type={"radio"}
                    onChange={(event) => {
                      handelRecentCall(event.target.value);
                    }}
                    checked={Settings.show_recent_call == 6 ? true : false}
                    name="total"
                    value="6"
                  />
                  6
                </span>
              </div>
            </div>
          </div>

          <div className="phone-set-row">
            <div className="phone-set-img">
              <img src={voicemail} />
            </div>
            <div className="phone-set-col-content">
              <h5 style={{}}>Turn Voicemail On or Off </h5>
              <div className="d-flex">
                <div className="col">
                  <h6 style={{ fontSize: "15px" }}>
                    Enable or Disable Voicemail Button{" "}
                    <sup className="info-icon-wrapper">
                      <img
                        src={informationIcon}
                        alt="Info"
                        className="info-icon"
                        style={{ cursor: "pointer" }}
                      />
                      {lovedOne.country === "Australia" ? (
                        <div className="popup-content">
                          This feature removes the voicemail button from your
                          Big
                          <b style={{ fontWeight: "bold" }}>Purple</b>Phone. If
                          you are with a carrier other than Telstra, you may
                          still need to contact your carrier to disable
                          voicemail.
                        </div>
                      ) : (
                        <div className="popup-content">
                          This feature removes the voicemail button from your
                          Big
                          <b style={{ fontWeight: "bold" }}>Purple</b>Phone.
                          Please note, you may still need to contact your
                          Carrier to enable or disable voicemail completely when
                          dialled.{" "}
                        </div>
                      )}
                    </sup>
                  </h6>
                  {lovedOne.country === "Australia" ? (
                    <h6
                      className="voice-mail-heading"
                      style={{ position: "relative" }}
                    >
                      Voicemail dials <span>Telstra 101</span> by default.
                      <br />
                      Change the number that is dialed when <br />
                      calling Voicemail for&nbsp;
                      <span
                        className="text-decoration-underline other-carriers-wrapper"
                        style={{ cursor: "pointer" }}
                      >
                        other Carriers
                        {lovedOne.country === "Australia" ? (
                          <div className="popup-content">
                            This feature removes the voicemail button from your
                            Big
                            <b style={{ fontWeight: "bold" }}>Purple</b>Phone.
                            If you are with a carrier other than Telstra, you
                            may still need to contact your carrier to disable
                            voicemail.
                          </div>
                        ) : (
                          <div className="popup-content">
                            This feature removes the voicemail button from your
                            Big
                            <b style={{ fontWeight: "bold" }}>Purple</b>Phone.
                            Please note, you may still need to contact your
                            Carrier to enable or disable voicemail completely
                            when dialled.{" "}
                          </div>
                        )}
                      </span>
                      .
                    </h6>
                  ) : (
                    <h6
                      className="voice-mail-heading"
                      style={{ position: "relative" }}
                    >
                      Enter the voicemail number for your SIM Carrier below,
                      then select <b style={{ fontWeight: "bold" }}>Save</b>.
                    </h6>
                  )}
                  {lovedOne.country !== "Australia" ? (
                    <div className="row">
                      <div className="col">
                        <input
                          className="form-control"
                          type="text"
                          placeholder="Enter Voicemail Number"
                          value={voicemailNumber}
                          onChange={(e) => {
                            const newValue = e.target.value
                              .trim()
                              .replace(/^(\+)?\+/, "$1")
                              .replace(/[^0-9+]/g, "")
                              .slice(0, 15);
                            setVoicemailNumber(newValue);
                          }}
                          maxLength={15}
                        />

                        {voicemailNumber.length > 15 && (
                          <div className="invalid-feedback">
                            Voicemail number cannot exceed 15 digits. Please
                            shorten it.
                          </div>
                        )}
                      </div>

                      <div className="col-auto">
                        <button
                          className="btn btn-primary-sm"
                          onClick={saveVoicemailNo}
                          disabled={voicemailNumber?.length <= 0}
                        >
                          Save
                        </button>
                      </div>
                    </div>
                  ) : null}
                </div>

                <div
                  className="form-check form-switch phone-set-switch-col"
                  style={{ right: "18px" }}
                >
                  <input
                    className="form-check-input"
                    type="checkbox"
                    id="voicemail"
                    checked={Settings.voice_mail ? true : false}
                    disabled={
                      lovedOne.country !== "Australia" &&
                      (!voicemailNumber || !enableToggleForOther)
                    }
                    onChange={() => {
                      lovedOne?.country == "United Kingdom" ||
                        lovedOne?.country == "New Zealand"
                        ? handelSettings(true)
                        : setOpenModal(true)
                    }}
                  />
                  <label
                    className="form-check-label"
                    htmlFor="voicemail"
                    style={{ margin: "0 6px" }}
                  ></label>
                </div>
              </div>
              {lovedOne.country === "Australia" ? (
                <div className="row mt-3">
                  <div className="col-12 col-sm mb-2 mb-sm-0">
                    <Select
                      className="voicemail_select"
                      options={options}
                      value={selectedValues} // Bind the selected value
                      onChange={handleChange}
                      formatOptionLabel={formatOptionLabel}
                    />
                  </div>
                  <div className="col-auto">
                    <button onClick={carrierSelect} className="btn-primary-sm">
                      Save
                    </button>
                  </div>
                </div>
              ) : null}
            </div>
          </div>
          <div className="phone-set-row">
            <div className="phone-set-img">
              <img src={iconsize} />
            </div>
            <div className="phone-set-col-content">
              <h5
                style={{
                  lineHeight: "27px",
                  color: "#372251",
                  fontFamily: "helveticaneuemedium",
                }}
              >
                Icon Size
              </h5>
              <div className="d-flex align-items-center">
                <h6 className="" style={{ fontSize: "16px" }}>
                  You can now enlarge icons on the{" "}
                  <spam>
                    Big<b>Purple</b>Phone
                  </spam>
                  .{" "}
                  <a
                    href="#"
                    style={{ textDecoration: "underline" }}
                    onClick={handelDemoModel(true)}
                  >
                    {" "}
                    What will enlarging look like?{" "}
                  </a>
                </h6>
                <div
                  className="form-check form-switch phone-set-switch-col"
                  style={{ right: "18px" }}
                >
                  {/* <span
                style={{
                  width: "80px",
                  textAlign: "left",
                  fontSize: "15px",
                }}
              >
                Small{" "}
              </span> */}
                  <input
                    className="form-check-input"
                    onChange={handelSize}
                    checked={Settings.avatars_size == 1 ? true : false}
                    type="checkbox"
                    id="size"
                  />
                  <label
                    className="form-check-label"
                    htmlFor="size"
                    style={{ margin: "0 6px" }}
                  ></label>
                  {/* <span style={{ fontSize: "15px" }}>Large</span> */}
                </div>
              </div>
            </div>
          </div>
          <div className="phone-set-row">
            <div className="phone-set-img">
              <img src={privatecaller} />
            </div>
            <div className="phone-set-col-content">
              <h5
                style={{
                  color: "#372251",
                  fontFamily: "helveticaneuemedium",
                  lineHeight: "1",
                  display: "inline-block",
                }}
              >
                Turn Private Callers List On or Off
              </h5>
              <div className="d-flex align-items-center">
                <h6 className="subTitleBlog" style={{ fontSize: "16px" }}>
                  You can now Enable or Disable the safe callers list. When
                  "Off" all calls will be received on {lovedOne.first_name}'s{" "}
                  <spam>
                    Big<b>Purple</b>Phone
                  </spam>
                  .
                </h6>
                <div
                  className="form-check form-switch phone-set-switch-col"
                  style={{ right: "18px" }}
                >
                  {/* <span
                style={{
                  width: "64px",
                  textAlign: "left",
                  fontSize: "15px",
                }}
              >
                Off{" "}
              </span> */}
                  <input
                    className="form-check-input"
                    type="checkbox"
                    id="nospam"
                    checked={Settings.no_spam ? true : false}
                    onChange={handleNoSpam}
                  />
                  <label
                    className="form-check-label"
                    htmlFor="nospam"
                    style={{ margin: "0 6px" }}
                  ></label>
                  {/* <span style={{ fontSize: "15px" }}>On</span> */}
                </div>
              </div>
            </div>
          </div>

          <div className="phone-set-row">
            <div className="phone-set-img">
              <img src={speaker} />
            </div>
            <div className="phone-set-col-content">
              <h5
                style={{
                  color: "#372251",
                  fontFamily: "helveticaneuemedium",
                  lineHeight: "1",
                  display: "inline-block",
                }}
              >
                Speaker
              </h5>
              <div className="d-flex align-items-center">
                <h6 className="subTitleBlog" style={{ fontSize: "16px" }}>
                  You can now Enable or Disable the Auto Speaker On or Off On{" "}
                  {lovedOne.first_name}'s{" "}
                  <spam>
                    Big<b>Purple</b>Phone
                  </spam>
                  .
                </h6>
                <div
                  className="form-check form-switch phone-set-switch-col"
                  style={{ right: "18px" }}
                >
                  {/* <span
                style={{
                  width: "64px",
                  textAlign: "left",
                  fontSize: "15px",
                }}
              >
                Off{" "}
              </span> */}
                  <input
                    className="form-check-input"
                    type="checkbox"
                    id="auto_speaker"
                    checked={Settings.is_auto_speaker === "true"}
                    onChange={handleSpeaker}
                  />
                  <label
                    className="form-check-label"
                    htmlFor="auto_speaker"
                    style={{ margin: "0 6px" }}
                  ></label>
                  {/* <span style={{ fontSize: "15px" }}>On</span> */}
                </div>
              </div>
            </div>
          </div>

          <div className="phone-set-row">
            <div className="phone-set-img">
              <img src={talkback} />
            </div>
            <div className="phone-set-col-content">
              <h5
                style={{
                  color: "#372251",
                  fontFamily: "helveticaneuemedium",
                  lineHeight: "1",
                  display: "inline-block",
                }}
              >
                BigPurpleTalk™
              </h5>
              <div className="d-flex align-items-center">
                <h6 className="subTitleBlog" style={{ fontSize: "16px" }}>
                  You can now Enable or Disable the BigPurpleTalk™ On or Off On{" "}
                  {lovedOne.first_name}'s{" "}
                  <spam>
                    Big<b>Purple</b>Phone
                  </spam>
                  .
                </h6>
                <div
                  className="form-check form-switch phone-set-switch-col"
                  style={{ right: "18px" }}
                >
                  <input
                    className="form-check-input"
                    type="checkbox"
                    id="talk_back"
                    checked={Settings.talk_back === "true"}
                    onChange={handleTalkBack}
                  />
                  <label
                    className="form-check-label"
                    htmlFor="talk_back"
                    style={{ margin: "0 6px" }}
                  ></label>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <CustomModal
        toggleModal={handleModal}
        isOpen={isOpenModal}
        title={"Confirmation"}
        description={
          <>
            <p className="mb-3 text-center">
              <img src={deleteConfirmIcon} alt="delete confirmation" />
            </p>
            <p className="text-center">
              Your request to turn {Settings.voice_mail ? "off" : "on"}{" "}
              voicemail will be sent to the carrier which can take upto 24 hours
              to take effect.{" "}
              {Settings.voice_mail
                ? "This will mean the phone will ring until it rings out."
                : ""}{" "}
              Are you sure?
            </p>
          </>
        }
        prompt={{
          title: "OK",
          cancel: "Cancel",
          handle: () => {
            let params = {};
            handelSettings(true);
          },
        }}
      />
      <CustomModal
        toggleModal={handelDemoModel}
        isOpen={isDemoOpenModal}
        description={
          <>
            <a
              style={{
                textAlign: "right",
                width: "100%",
                display: "inline-block",
              }}
              onClick={() => setDemoOpenModal(false)}
              href="#"
            >
              {" "}
              <img src={closeIcon} alt="" width="12px" /> Close
            </a>
            <img src={loPhoneDemo} alt="Enlarged icons on BigPurplePhone" />
          </>
        }
        close={false}
      />
    </div>
  );
};
export default PhoneMenuScreen;

// import { useEffect, useState } from "react";
// import { NavLink, useNavigate } from "react-router-dom";
// import useSelectedLovedOne from "hooks/useSelectedLovedOne";
// import { getSettings, updateSelectToSpeak, updateSettings, updateSpeakerSetting, updateTalkBackSetting } from "../api/api";
// import back_white from "assets/images/back_white.svg";
// import { useErrorHandler } from "react-error-boundary";
// import CustomModal from "components/Modal/modal";
// import deleteConfirmIcon from "assets/images/delete_confrm.svg";
// import loPhoneDemo from "assets/images/Enlarged_icons_on_BigPurplePhone.png";
// import closeIcon from "assets/images/pop_close_icon.svg";
// // import voicemail from "assets/images/voicemail-icon.svg";
// import voicemail from "assets/images/voiceCall.png"
// import selectToSpeak from "assets/images/selectToSpeak.png"
// import recentCallIcon from "assets/images/recent-call-icon.svg"
// import iconsize from "assets/images/iconsize-icon.svg";
// import privatecaller from "assets/images/privatecaller-icon.svg";
// import speaker from "assets/images/speaker-icon.svg";
// // import talkback from "assets/images/talkback-icon.svg"
// import talkback from "assets/images/big-pupletalk.svg"
// // big-pupletalk-1
// import { speakText } from "utils/utils";
// import { useDispatch, useSelector } from "react-redux";
// import { updateSettingsSlice } from "redux/settingsSlice";

// const PhoneMenuScreen = () => {
//   const [isLoading, setLoading] = useState(false);
//   const [isOpenModal, setOpenModal] = useState(false);
//   const [isDemoOpenModal, setDemoOpenModal] = useState(false);
//   const lovedOne = useSelectedLovedOne();
//   const [Settings, setSettings] = useState({});
//   const handleError = useErrorHandler();
//   const dispatch = useDispatch();
//   const settings = useSelector((state) => state.settings);

//   useEffect(() => {
//     setLoading(true);
//     if (lovedOne && lovedOne.id) {
//       getSettings(lovedOne?.id ?? "")
//         .then((data) => {
//           setLoading(false);
//           setSettings(data);
//         })
//         .catch((error) => {
//           return error.response.data.statusCode != 400
//             ? handleError(error)
//             : "";
//         });
//     }
//   }, [lovedOne && lovedOne.id, setSettings]);
//   const handelRecentCall = (value) => {
//     setLoading(true);
//     setSettings({ ...Settings, show_recent_call: parseInt(value) });
//     updateSettings({
//       ...Settings,
//       lovedOneId: lovedOne?.id,
//       show_recent_call: parseInt(value),
//     })
//       .then((data) => {
//         setLoading(false);
//       })
//       .catch((error) => {
//         return error.response.data.statusCode != 400 ? handleError(error) : "";
//       });
//   };
//   const handelSettings = (value) => {
//     value = Settings.voice_mail ? 0 : 1;
//     setSettings({ ...Settings, voice_mail: value });
//     setOpenModal(false);
//     updateSettings({ ...Settings, lovedOneId: lovedOne?.id, voice_mail: value })
//       .then((data) => {
//         const statusText = value ? 'enabled' : 'disabled';
//         speakText(`Voicemail is now ${statusText} on the BigPurplePhone`, true);
//       })
//       .catch((error) => {
//         return error.response.data.statusCode != 400 ? handleError(error) : "";
//       })
//       .finally(() => {
//         setOpenModal(false);
//       });
//   };
//   const handleNoSpam = () => {
//     let value = Settings.no_spam ? 0 : 1;

//     setSettings({ ...Settings, no_spam: value });
//     updateSettings({ ...Settings, lovedOneId: lovedOne?.id, no_spam: value })
//       .then((data) => {
//         const statusText = value ? 'enabled' : 'disabled';
//         speakText(`Private Caller is now ${statusText} on the BigPurplePhone`, true);
//       })
//       .catch((error) => {
//         return error.response.data.statusCode != 400 ? handleError(error) : "";
//       })
//       .finally(() => {
//         setOpenModal(false);
//       });
//   };
//   const handleSpeaker = () => {
//     // Toggle the value of auto_speaker
//     const newValue = Settings.is_auto_speaker === "true" ? "false" : "true";

//     const apiValue = Settings.is_auto_speaker === "true" ? "1" : "0";
//     // Update local state immediately to reflect the change in UI
//     setSettings({ ...Settings, is_auto_speaker: newValue });

//     // Make API call to update the setting
//     updateSpeakerSetting({ lovedOneId: lovedOne?.id, auto_speaker: apiValue })
//       .then(() => {
//         // Optionally, you can add any post-update logic here
//         const statusText = newValue === "true" ? 'enabled' : 'disabled';
//         speakText(`Auto Speaker is now ${statusText} on the BigPurplePhone`, true);
//       })
//       .catch((error) => {
//         // Handle errors if needed
//         console.error("Error updating auto speaker setting:", error);
//         // Rollback the UI state if the API call fails
//         setSettings({ ...Settings, is_auto_speaker: Settings.is_auto_speaker === "true" ? "false" : "true" });
//       });
//   };

//   const handleTalkBack = () => {
//     // Toggle the value of auto_speaker
//     const newValue = Settings.talk_back === "true" ? "false" : "true";

//     const apiValue = Settings.talk_back === "true" ? "1" : "0";
//     // Update local state immediately to reflect the change in UI
//     setSettings({ ...Settings, talk_back: newValue });

//     // Make API call to update the setting
//     updateTalkBackSetting({ talk_back: apiValue, lovedOneId: lovedOne?.id })
//       .then(() => {
//         // Optionally, you can add any post-update logic here
//         const statusText = newValue === "true" ? 'enabled' : 'disabled';
//         speakText(`BigPurple Talk is now ${statusText} on the BigPurplePhone`, true);
//       })
//       .catch((error) => {
//         // Handle errors if needed
//         console.error("Error updating talkBackSetting setting:", error);
//         // Rollback the UI state if the API call fails
//         setSettings({ ...Settings, talk_back: Settings.talk_back === "true" ? "false" : "true" });
//       });
//   };

//   const handleSelectToSpeak = () => {
//     const newValue = Settings.select_to_speak === "true" ? "false" : "true";
//     const apiValue = newValue === "true" ? "0" : "1";

//     setSettings({ ...Settings, select_to_speak: newValue });

//     // Update Redux state
//     dispatch(updateSettingsSlice({
//       lovedOneId: lovedOne.id,
//       settings: { select_to_speak: newValue, loved_one_id: lovedOne.id },
//     }));

//     // Make API call to update the setting
//     updateSelectToSpeak({ select_to_speak: apiValue, lovedOneId: lovedOne.id })
//       .then(() => {
//         const statusText = newValue === "true" ? "enabled" : "disabled";
//         speakText(`Select to speak is now ${statusText}`, newValue);
//       })
//       .catch((error) => {
//         console.error("Error updating selectToSpeak setting:", error);

//       });
//   };
//   const handleModal = (status) => () => {
//     setOpenModal(status);
//   };
//   const handelDemoModel = (status) => () => {
//     setDemoOpenModal(status);
//   };
//   const handelSize = (value) => {
//     value = Settings.avatars_size ? 0 : 1;
//     setSettings({ ...Settings, avatars_size: value });
//     updateSettings({
//       ...Settings,
//       lovedOneId: lovedOne?.id,
//       avatars_size: value,
//     })
//       .then((data) => {
//         const statusText = value ? 'larger' : 'smaller';
//         speakText(`Icon Size is  now ${statusText} on the BigPurplePhone`,true);
//       })
//       .catch((error) => {
//         return error.response.data.statusCode != 400 ? handleError(error) : "";
//       });
//   };
//   return (
//     <div className="mainPage">
//       <div className="Homeheaderbg">
//         <div className="">
//           <NavLink to="/MenuScreen">
//             <img src={back_white} alt=""
//               onClick={() => speakText('Back', true)}
//             />
//           </NavLink>
//         </div>
//         <div className="customer-info">
//           <h1 className="w-100">
//             <span>Phone Settings</span>
//           </h1>
//         </div>

//         <div className="customer-info">
//           <div className="col-md-6"></div>
//           <div className="col-md-6"></div>
//         </div>
//       </div>

//       <div className="parent titleHeadBox">
//         <div className="contentboxgray11">
//           <br />
//         </div>
//       </div>
//       <div className="contentBody contentbodymedia manageapps">
//         <div className="">
//           {/* <h6 style={{ marginBottom: "0px" }}>
//             <span
//               style={{
//                 lineHeight: "27px",
//                 color: "#372251",
//                 fontFamily: "helveticaneuemedium",
//                 fontSize: "18px",
//               }}
//             >
//               Display Recent Calls{" "}
//             </span>
//           </h6>
//           <p className="subTitleBlog mb-2" style={{ lineHight: "1.4" }}>
//             Set the number of recent calls that displays on{" "}
//             {lovedOne.first_name}'s Phone
//           </p>

//           <div className="row" style={{ marginBottom: "20px" }}>
//             <span className="text-left contact-radio">
//               <input
//                 type={"radio"}
//                 onChange={(event) => {
//                   handelRecentCall(event.target.value);
//                 }}
//                 checked={Settings.show_recent_call == 0 ? true : false}
//                 name="total"
//                 value="0"
//               />
//               0
//             </span>
//             <span className="text-left contact-radio">
//               <input
//                 type={"radio"}
//                 onChange={(event) => {
//                   handelRecentCall(event.target.value);
//                 }}
//                 checked={Settings.show_recent_call == 3 ? true : false}
//                 name="total"
//                 value="3"
//               />
//               3
//             </span>
//             <span className="text-left contact-radio">
//               <input
//                 type={"radio"}
//                 onChange={(event) => {
//                   handelRecentCall(event.target.value);
//                 }}
//                 checked={Settings.show_recent_call == 6 ? true : false}
//                 name="total"
//                 value="6"
//               />
//               6
//             </span>
//           </div> */}

//           <div className="phone-set-row">
//             <div className="phone-set-img">
//               <img src={recentCallIcon} />
//             </div>
//             <div className="phone-set-col-lft">
//               <h5
//                 style={{

//                 }}
//               >
//                 Display Recent Calls{" "}
//               </h5>
//               <div className="d-flex align-items-center">
//                 <p className="subTitleBlog mb-2" style={{ lineHight: "1.4" }}>
//                   Set the number of recent calls that displays on{" "}
//                   {lovedOne.first_name}'s Phone
//                 </p>
//               </div>
//               <div className="row" style={{ marginBottom: "0px" }}>
//                   <span className="text-left contact-radio">
//                     <input
//                       type={"radio"}
//                       onChange={(event) => {
//                         handelRecentCall(event.target.value);
//                       }}
//                       checked={Settings.show_recent_call == 0 ? true : false}
//                       name="total"
//                       value="0"
//                     />
//                     0
//                   </span>
//                   <span className="text-left contact-radio">
//                     <input
//                       type={"radio"}
//                       onChange={(event) => {
//                         handelRecentCall(event.target.value);
//                       }}
//                       checked={Settings.show_recent_call == 3 ? true : false}
//                       name="total"
//                       value="3"
//                     />
//                     3
//                   </span>
//                   <span className="text-left contact-radio">
//                     <input
//                       type={"radio"}
//                       onChange={(event) => {
//                         handelRecentCall(event.target.value);
//                       }}
//                       checked={Settings.show_recent_call == 6 ? true : false}
//                       name="total"
//                       value="6"
//                     />
//                     6
//                   </span>
//               </div>

//             </div>

//           </div>

//           <div className="phone-set-row">
//             <div className="phone-set-img">
//               <img src={voicemail} />
//             </div>
//             <div className="phone-set-col-lft">
//               <h5
//                 style={{

//                 }}
//               >
//                 Turn Voicemail On or Off{" "}
//               </h5>
//               <div className="d-flex align-items-center">
//                 <h6 className="col" style={{ fontSize: "16px" }}>
//                   Enable or Disable Voicemail on the{" "}
//                   <spam>
//                     Big<b>Purple</b>Phone
//                   </spam>
//                 </h6>
//                 <div className="form-check form-switch phone-set-switch-col"
//                   style={{ right: "18px" }}
//                 >
//                   {/* <span
//                 style={{
//                   width: "64px",
//                   textAlign: "left",
//                   fontSize: "15px",
//                 }}
//               >
//                 Off{" "}
//               </span> */}
//                   <input
//                     className="form-check-input"
//                     type="checkbox"
//                     id="voicemail"
//                     checked={Settings.voice_mail ? true : false}
//                     onChange={handleModal(true)}
//                   />
//                   <label
//                     className="form-check-label"
//                     htmlFor="voicemail"
//                     style={{ margin: "0 6px" }}
//                   ></label>
//                   {/* <span style={{ fontSize: "15px" }}>On</span> */}
//                 </div>
//               </div>

//             </div>

//           </div>
//           <div className="phone-set-row">
//             <div className="phone-set-img">
//               <img src={iconsize} />
//             </div>
//             <div className="phone-set-col-lft">
//               <h5
//                 style={{
//                   lineHeight: "27px",
//                   color: "#372251",
//                   fontFamily: "helveticaneuemedium",
//                 }}
//               >
//                 Icon Size
//               </h5>
//               <div className="d-flex align-items-center">
//                 <h6 className="" style={{ fontSize: "16px" }}>
//                   You can now enlarge icons on the{" "}
//                   <spam>
//                     Big<b>Purple</b>Phone
//                   </spam>
//                   .{" "}
//                   <a
//                     href="#"
//                     style={{ textDecoration: "underline" }}
//                     onClick={handelDemoModel(true)}
//                   >
//                     {" "}
//                     What will enlarging look like?{" "}
//                   </a>
//                 </h6>
//                 <div
//                   className="form-check form-switch phone-set-switch-col"
//                   style={{ right: "18px" }}
//                 >
//                   {/* <span
//                 style={{
//                   width: "80px",
//                   textAlign: "left",
//                   fontSize: "15px",
//                 }}
//               >
//                 Small{" "}
//               </span> */}
//                   <input
//                     className="form-check-input"
//                     onChange={handelSize}
//                     checked={Settings.avatars_size == 1 ? true : false}
//                     type="checkbox"
//                     id="size"
//                   />
//                   <label
//                     className="form-check-label"
//                     htmlFor="size"
//                     style={{ margin: "0 6px" }}
//                   ></label>
//                   {/* <span style={{ fontSize: "15px" }}>Large</span> */}
//                 </div>
//               </div>

//             </div>

//           </div>
//           <div className="phone-set-row">
//             <div className="phone-set-img">
//               <img src={privatecaller} />
//             </div>
//             <div className="phone-set-col-lft">
//               <h5
//                 style={{
//                   color: "#372251",
//                   fontFamily: "helveticaneuemedium",
//                   lineHeight: "1",
//                   display: "inline-block",
//                 }}
//               >
//                 Turn Private Callers List On or Off
//               </h5>
//               <div className="d-flex align-items-center">
//                 <h6 className="subTitleBlog" style={{ fontSize: "16px" }}>
//                   You can now Enable or Disable the safe callers
//                   list. When "Off" all calls will be received on{" "}
//                   {lovedOne.first_name}'s{" "}
//                   <spam>
//                     Big<b>Purple</b>Phone
//                   </spam>
//                   .
//                 </h6>
//                 <div
//                   className="form-check form-switch phone-set-switch-col"
//                   style={{ right: "18px" }}
//                 >
//                   {/* <span
//                 style={{
//                   width: "64px",
//                   textAlign: "left",
//                   fontSize: "15px",
//                 }}
//               >
//                 Off{" "}
//               </span> */}
//                   <input
//                     className="form-check-input"
//                     type="checkbox"
//                     id="nospam"
//                     checked={Settings.no_spam ? true : false}
//                     onChange={handleNoSpam}
//                   />
//                   <label
//                     className="form-check-label"
//                     htmlFor="nospam"
//                     style={{ margin: "0 6px" }}
//                   ></label>
//                   {/* <span style={{ fontSize: "15px" }}>On</span> */}
//                 </div>
//               </div>

//             </div>

//           </div>

//           <div className="phone-set-row">
//             <div className="phone-set-img">
//               <img src={speaker} />
//             </div>
//             <div className="phone-set-col-lft">
//               <h5
//                 style={{
//                   color: "#372251",
//                   fontFamily: "helveticaneuemedium",
//                   lineHeight: "1",
//                   display: "inline-block",
//                 }}
//               >
//                 Speaker
//               </h5>
//               <div className="d-flex align-items-center">
//                 <h6 className="subTitleBlog" style={{ fontSize: "16px" }}>
//                   You can now Enable or Disable the Auto Speaker On or Off On{" "}
//                   {lovedOne.first_name}'s{" "}
//                   <spam>
//                     Big<b>Purple</b>Phone
//                   </spam>
//                   .
//                 </h6>
//                 <div
//                   className="form-check form-switch phone-set-switch-col"
//                   style={{ right: "18px" }}
//                 >
//                   {/* <span
//                 style={{
//                   width: "64px",
//                   textAlign: "left",
//                   fontSize: "15px",
//                 }}
//               >
//                 Off{" "}
//               </span> */}
//                   <input
//                     className="form-check-input"
//                     type="checkbox"
//                     id="auto_speaker"
//                     checked={Settings.is_auto_speaker === "true"}
//                     onChange={handleSpeaker}
//                   />
//                   <label
//                     className="form-check-label"
//                     htmlFor="auto_speaker"
//                     style={{ margin: "0 6px" }}
//                   ></label>
//                   {/* <span style={{ fontSize: "15px" }}>On</span> */}
//                 </div>
//               </div>

//             </div>

//           </div>

//           <div className="phone-set-row">
//             <div className="phone-set-img">
//               <img src={talkback} />
//             </div>
//             <div className="phone-set-col-lft" >
//               <h5
//                 style={{
//                   color: "#372251",
//                   fontFamily: "helveticaneuemedium",
//                   lineHeight: "1",
//                   display: "inline-block",
//                 }}
//               >
//                 BigPurpleTalk™
//               </h5>
//               <div className="d-flex align-items-center">
//                 <h6 className="subTitleBlog" style={{ fontSize: "16px" }}>
//                   You can now Enable or Disable the BigPurpleTalk™ On or Off On{" "}
//                   {lovedOne.first_name}'s{" "}
//                   <spam>
//                     Big<b>Purple</b>Phone
//                   </spam>
//                   .
//                 </h6>
//                 <div
//                   className="form-check form-switch phone-set-switch-col"
//                   style={{ right: "18px" }}
//                 >
//                   <input
//                     className="form-check-input"
//                     type="checkbox"
//                     id="talk_back"
//                     checked={Settings.talk_back === "true"}
//                     onChange={handleTalkBack}
//                   />
//                   <label
//                     className="form-check-label"
//                     htmlFor="talk_back"
//                     style={{ margin: "0 6px" }}
//                   ></label>
//                 </div>
//               </div>

//             </div>

//           </div>
//           {/* <div className="phone-set-row">
//             <div className="phone-set-img">
//               <img src={selectToSpeak} />
//             </div>
//             <div className="phone-set-col-lft">
//               <h5
//                 style={{

//                 }}
//               >
//                 Accessibility{" "}
//               </h5>
//               <div className="d-flex align-items-center">
//                 <h6 className="col" style={{ fontSize: "16px" }}>
//                   Turn off "Select to Speak" feature{" "}
//                 </h6>
//                 <div className="form-check form-switch phone-set-switch-col"
//                   style={{ right: "18px" }}
//                 >
//                   <input
//                     className="form-check-input"
//                     type="checkbox"
//                     id="select_to_speak"
//                     checked={Settings.select_to_speak === "true"}
//                     onChange={handleSelectToSpeak}
//                   />
//                   <label
//                     className="form-check-label"
//                     htmlFor="select_to_speak"
//                     style={{ margin: "0 6px" }}
//                   ></label>
//                 </div>
//               </div>

//             </div>

//           </div> */}

//         </div>
//       </div>

//       <CustomModal
//         toggleModal={handleModal}
//         isOpen={isOpenModal}
//         title={"Confirmation"}
//         description={
//           <>
//             <p className="mb-3 text-center">
//               <img src={deleteConfirmIcon} alt="delete confirmation" />
//             </p>
//             <p className="text-center">
//               Your request to turn {Settings.voice_mail ? "off" : "on"}{" "}
//               voicemail will be sent to the carrier which can take upto 24 hours
//               to take effect.{" "}
//               {Settings.voice_mail
//                 ? "This will mean the phone will ring until it rings out."
//                 : ""}{" "}
//               Are you sure?
//             </p>
//           </>
//         }
//         prompt={{
//           title: "OK",
//           cancel: "Cancel",
//           handle: () => {
//             let params = {};
//             handelSettings(true);
//           },
//         }}
//       />
//       <CustomModal
//         toggleModal={handelDemoModel}
//         isOpen={isDemoOpenModal}
//         description={
//           <>
//             <a
//               style={{
//                 textAlign: "right",
//                 width: "100%",
//                 display: "inline-block",
//               }}
//               onClick={() => setDemoOpenModal(false)}
//               href="#"
//             >
//               {" "}
//               <img src={closeIcon} alt="" width="12px" /> Close
//             </a>
//             <img src={loPhoneDemo} alt="Enlarged icons on BigPurplePhone" />
//           </>
//         }
//         close={false}
//       />
//     </div>
//   );
// };
// export default PhoneMenuScreen;
